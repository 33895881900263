import React, { useState, useRef } from 'react';
import {
  IconButton,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';

// StyleKeys = "base" | "popperContainer" ...
// Record<StyleKeys, SystemStyleObject<Theme>>

const styles = {
  base: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  popperContainer: {
    marginTop: '20px',
    backgroundColor: 'treBackground',
    color: 'fontColor',
    minHeight: '180px',
    minWidth: '160px',
    overflow: 'hidden',
  },
  popperTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#222222',
  },
  margin: {
    marginLeft: '10px',
  },
  formControl: {
    marginTop: '10px',
  },
  iconButton: {
    backgroundColor: 'white',
    border: '0.5px solid #666',
    opacity: 0.8,
    padding: 0,
    '& svg': {
      fontSize: '4vw',
    },
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.6,
    },
    '@media (max-width: 800px)': {
      '& svg': {
        fontSize: '10vw',
      },
    },
    '@media (min-width: 800px) and (max-width: 1200px)': {
      '& svg': {
        fontSize: '6vw',
      },
    },
  },
  closeIcon: {
    color: 'white',
  },
  layerIcon: {
    color: 'treBackground',
  },
  opacity: {
    opacity: 0.9,
  },
};

interface Props {
  onRangeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hourRanges: ('0-4h' | '4-12h' | '12-24h')[];
  selectedHourRanges: ('0-4h' | '4-12h' | '12-24h')[];
}

const RangeSelector = (props: Props) => {
  const [open, setOpen] = useState(false);
  const rangeButton = useRef(null);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <Box sx={{ ...styles.base }}>
      <IconButton
        sx={styles.iconButton}
        ref={rangeButton}
        onClick={handleClick}
      >
        <LayersIcon sx={styles.layerIcon} />
      </IconButton>
      {rangeButton?.current && (
        <Popper
          sx={styles.opacity}
          open={open}
          anchorEl={rangeButton.current}
          placement={'bottom-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={styles.popperContainer}>
                <Box sx={styles.popperTitle}>
                  <Typography variant='h3' sx={styles.margin}>
                    {' '}
                    Tarkastelujaksot{' '}
                  </Typography>
                  <IconButton onClick={handleClick} color='primary'>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
                <FormControl
                  component='fieldset'
                  sx={{ ...styles.margin, ...styles.formControl }}
                >
                  <Typography variant='h4'>
                    {' '}
                    Valitse tarkastelujakso{' '}
                  </Typography>
                  <FormGroup>
                    {props.hourRanges.map((range) => (
                      <FormControlLabel
                        key={`range-selector-${range}`}
                        control={
                          <Checkbox
                            color='info'
                            checked={props.selectedHourRanges.includes(
                              range as (typeof props.selectedHourRanges)[number]
                            )}
                            onChange={props.onRangeChange}
                            name={range}
                          />
                        }
                        label={`Ajoneuvot kartalla ${range}`}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </Box>
  );
};

export default RangeSelector;
