/** React OpenLayers wrapper
 * @see https://openlayers.org/
 */

/** React */
import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/** OL imports */
import 'ol/ol.css';
import OLMap from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import Projection from 'ol/proj/Projection';
import Overlay from 'ol/Overlay';
import { defaults as defaultInteractions } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import WMTS from 'ol/source/WMTS';
import TileWMS from 'ol/source/TileWMS';
import { getUid } from 'ol/util';

interface Props {
  center: any;
  features: any;
  layers: (TileLayer<WMTS> | TileLayer<TileWMS>)[];
  projection: Projection;
  minZoom: number;
  maxZoom: number;
  zoom: any;
}

/** Map component */
const Map = (props: Props) => {
  const [featureVectorSource] = useState(() => {
    return new VectorSource({
      features: [],
    });
  });

  const [featureLayer] = useState(() => {
    return new VectorLayer({
      source: featureVectorSource,
      zIndex: 105,
    });
  });

  /**
   * OpenLayers View: @see https://openlayers.org/en/latest/apidoc/module-ol_View-View.html
   * View's projection is defined based on the target country (area): E.g. EPSG:3067 in Finland
   */

  const [olView] = useState(() => {
    return new View({
      center: [0, 0],
      extent: [313753, 6812223, 351129, 6861143],
      /** Projection for displaying map tiles properly */
      projection: props.projection,
      zoom: props.zoom,
      maxZoom: props.maxZoom,
      minZoom: props.minZoom,
      multiWorld: false,
      enableRotation: false,
    });
  });

  /**
   * OpenLayers Map: @see https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html
   * "For a map to render, a view, one or more layers, and a target container are needed" -docs
   */
  const [olMap] = useState(() => {
    return new OLMap({
      layers: [featureLayer],
      target: '',
      controls: [],
      view: olView,
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false,
        doubleClickZoom: false,
        shiftDragZoom: false,
      }),
    });
  });

  /**
   * OpenLayers Overlay element
   * @see https://openlayers.org/en/latest/apidoc/module-ol_Overlay.html
   * 'positioning' attribute specifies the overlay -elements position respect to its own 'position' property
   * 'offset' specifies the offset [horizontal, vertical] (in pixels) when positioning the overlay
   * Set Overlay's 'position' -property with Overlay.setPosition() method
   */
  const [olOverlay] = useState(() => {
    return new Overlay({
      stopEvent: true,
    });
  });

  /* Map reference definitions */
  const mapRef = useRef<HTMLElement>(null);
  const overlayRef = useRef(null);

  /** Mount map to its container after the Map instance is created */
  useEffect(() => {
    if (!mapRef?.current) return;
    olMap.setTarget(mapRef.current);
  }, [olMap]);

  /** Update map features */
  useEffect(() => {
    featureVectorSource.clear();
    if (props.features) {
      featureVectorSource.addFeatures(props.features);
    }
  }, [props.features]);

  /** Mount Overlay -instance to its reference container, and bind it to Map */
  useEffect(() => {
    olMap.addOverlay(olOverlay);
    if (!overlayRef?.current) return;
    olOverlay.setElement(overlayRef.current);
  }, [olOverlay]);

  /** Add OpenLayer-layers to map */
  useEffect(() => {
    if (!props.layers) return;
    const existingLayers = olMap.getLayers().getArray();

    props.layers.forEach((layer) => {
      if (
        existingLayers.some((existingLayer) => {
          return existingLayer.getProperties().map.ol_uid === getUid(layer);
        })
      )
        return;
      try {
        olMap.addLayer(layer);
      } catch (error) {
        if (error instanceof Error) {
          console.warn(error.message);
        }
        console.warn('Error while adding layer');
      }
    });
  }, [props.layers]);

  /** Center map based on received props */
  useEffect(() => {
    if (props.center && props.center.length !== 0) {
      olMap.getView().setCenter(props.center);
    }
  }, [props.center]);

  /** Update Map's zoom level */
  useEffect(() => {
    if (props.zoom) {
      olView.setZoom(props.zoom);
    }
  }, [props.zoom]);

  /** Actual HTML content of the overlay -element */
  // const overlayContent = React.Children.toArray(props.children).find(child => child.type.displayName === 'Overlay');

  /** Shifts the application's focus back to the map container. */
  const reFocus = () => {
    if (mapRef?.current) mapRef.current.focus();
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        ref={mapRef}
        tabIndex={1}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(256, 256, 256, 0.0)',
          outline: 0,
          '&:focus': {
            outline: `orange solid 2px`,
          },
        }}
        onMouseEnter={reFocus}
        onTouchMove={reFocus}
      />
    </Box>
  );
};

Map.displayName = 'Map';

/** Props definitions */
Map.propTypes = {
  /** Center map to specified coordinates in [x, y] format */
  center: PropTypes.arrayOf(PropTypes.number),
  /** React elements passed as children to Map component */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Event/callback pairs to pass different map events above */
  eventListeners: PropTypes.arrayOf(
    PropTypes.exact({
      eventType: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
  /** OpenLayers Features that can be added to a VectorSource */
  features: PropTypes.arrayOf(PropTypes.object),
  /** OpenLayers interactions that change over time (Draw, Snap, DragBox etc.)
   * @see https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
   */
  interactions: PropTypes.arrayOf(PropTypes.object),
  /** OpenLayers TileLayers which are added to the map instance
   * @see https://openlayers.org/en/latest/apidoc/module-ol_layer_Tile-TileLayer.html
   */
  layers: PropTypes.arrayOf(PropTypes.object),
  /** Projection of the map
   * @see https://openlayers.org/en/latest/apidoc/module-ol_proj_Projection-Projection.html
   */
  projection: PropTypes.oneOfType([
    PropTypes.instanceOf(Projection),
    PropTypes.object,
  ]).isRequired,
  /** OpenLayers might need some refreshing after DOM changes */
  refresher: PropTypes.bool,
  /** Toggler for focusing the map on demand */
  toggleFocus: PropTypes.bool,
  /** Maximum extent of the map's view, in corresponding coordinate system
   * @see https://openlayers.org/en/latest/apidoc/module-ol_extent.html#~Extent
   */
  extent: PropTypes.array,
  /** Zoom level for the view (e.g. 12) */
  zoom: PropTypes.number,
  /** Minimum zoom level for the view */
  minZoom: PropTypes.number,
};

/** Default props */
Map.defaultProps = {
  center: [327000, 6822500],
  zoom: 5,
  maxZoom: 15,
  minZoom: 3,
};

export default Map;
