import { Alert, Snackbar, AlertProps } from '@mui/material';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

interface Notification {
  message: string;
  type: AlertProps['severity'];
}

const NotificationContext = createContext({
  notify: (_data: Notification) => {},
});

export function NotificationProvider({ children }: PropsWithChildren) {
  const [message, setMessage] = useState<Notification | null>(null);

  const notify = (data: Notification) => {
    if (!message || data?.type !== message?.type)
      setMessage({
        message: data.message,
        type: data.type,
      });
  };

  function handleClose(_event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(null);
  }

  return (
    <NotificationContext.Provider value={{ notify }}>
      {message && (
        <Snackbar
          open={message !== undefined}
          autoHideDuration={null}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={message.type}>
            {message.message}
          </Alert>
        </Snackbar>
      )}
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
}
