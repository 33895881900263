import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';

// StyleKeys = "base" | "popperContainer" ...
// Record<StyleKeys, SystemStyleObject<Theme>>

const styles = {
  base: {
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
  popperContainer: {
    marginTop: '20px',
    backgroundColor: 'treBackground',
    color: 'fontColor',
    minHeight: '180px',
    width: '280px',
    overflow: 'hidden',
  },
  popperTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#222222',
  },
  margin: {
    marginLeft: '10px',
  },
  formControl: {
    marginTop: '10px',
  },
  iconButton: {
    backgroundColor: 'white',
    border: '0.5px solid #666',
    opacity: '0.8',
    padding: 0,
    '& svg': {
      fontSize: '4vw',
    },
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.6,
    },
    '@media (max-width: 800px)': {
      '& svg': {
        fontSize: '10vw',
      },
    },
    '@media (min-width: 800px) and (max-width: 1200px)': {
      '& svg': {
        fontSize: '6vw',
      },
    },
  },
  helpIcon: {
    color: 'treBackground',
  },
  closeIcon: {
    color: 'white',
  },
  opacity: {
    opacity: 0.9,
  },
  text: {
    margin: '10px',
  },
  lineSeparator: {
    margin: '5%',
    width: '90%',
    borderBottom: '1px solid grey',
  },
  block: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  blockContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '5px',
  },
  blockRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '4px',
  },
};

const Info = () => {
  const [open, setOpen] = useState(true);
  const infoButton = useRef(null);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <Box sx={styles.base}>
      <IconButton sx={styles.iconButton} ref={infoButton} onClick={handleClick}>
        <HelpIcon sx={styles.helpIcon} />
      </IconButton>
      {infoButton?.current && (
        <Popper
          sx={styles.opacity}
          open={open}
          anchorEl={infoButton.current}
          placement={'bottom-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={styles.popperContainer}>
                <Box sx={styles.popperTitle}>
                  <Typography variant="h3" sx={styles.margin}>
                    {' '}
                    Tampereen aurat kartalla{' '}
                  </Typography>
                  <IconButton onClick={handleClick}>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
                <Typography sx={styles.text}>
                  Kartalla esitetään Tampereen kaupungin aurojen ja työkoneiden
                  kunnossapitotyöt lähituntien ajalta.
                </Typography>
                <Typography sx={styles.text}>
                  Oletuksena kartalla näkyy viimeisen neljän tunnin aikana
                  tehdyt kunnossapitotyöt.
                </Typography>
                <Typography sx={styles.text}>
                  Oikealta yläkulmasta aukeaa tarkasteluvälivalikko, josta voi
                  vaihtaa kunnossapidon aikaväliä.
                </Typography>
                <Box sx={styles.lineSeparator} />
                <Typography variant="h4" sx={styles.text}>
                  Värien merkitykset:
                </Typography>
                <Box sx={styles.blockContainer}>
                  <Box sx={styles.blockRow}>
                    <Box
                      sx={styles.block}
                      style={{ backgroundColor: '#19FFF1' }}
                    />
                    <Typography> Lumiaurat </Typography>
                  </Box>
                  <Box sx={styles.blockRow}>
                    <Box
                      sx={styles.block}
                      style={{ backgroundColor: '#fd0ae9' }}
                    />
                    <Typography> Puhtaanapito tai auran alusterä </Typography>
                  </Box>
                  <Box sx={styles.blockRow}>
                    <Box
                      sx={styles.block}
                      style={{ backgroundColor: '#913333' }}
                    />
                    <Typography> Hiekoitus </Typography>
                  </Box>
                  <Box sx={styles.blockRow}>
                    <Box
                      sx={styles.block}
                      style={{ backgroundColor: '#4a7bf7' }}
                    />
                    <Typography> Luokittelematon </Typography>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </Box>
  );
};

export default Info;
