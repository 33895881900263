/**
 * MUI theme
 * @see https://material-ui.com/customization/theming/
 */

import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    treBackground: Palette['primary'];
    fontColor: Palette['primary'];
  }
  interface PaletteOptions {
    treBackground?: string;
    fontColor?: string;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    useNextVariants: boolean;
  }
  interface TypographyOptions {
    useNextVariants?: boolean;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: blue[200],
    },
    treBackground: '#36363f',
    fontColor: '#FFFFFF',
  },
  typography: {
    fontFamily: 'Lato,Helvetica,Arial,sans-serif',
    fontSize: 12,
    useNextVariants: true,
    h3: {
      fontSize: 16,
    },
    h4: {
      fontSize: 14,
    },
  },
});
