import { Box, LinearProgress, ThemeProvider } from '@mui/material';
import AddressSearch from '@src/components/AddressSearch/AddressSearch';
import Info from '@src/components/Info/Info';
import EPSG3067 from '@src/components/Map/EPSG3067';
import Map from '@src/components/Map/Map';
import RangeSelector from '@src/components/RangeSelector/RangeSelector';
import { theme } from '@src/themes/theme';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

/** BaseMap */
import { auratWMS, virastokarttaMV } from '@src/components/Map/baseMaps';
import DataController from '@src/controllers/DataController';
import { createVehicleFeatures } from './ApplicationFunctions';
import { useNotification } from './Notification/NotificationProvider';

const applicationTheme = theme;

/** Application entry point wrapper */
const Application = () => {
  /** Center of the last address searched */
  const [addressCenter, setAddressCenter] = useState<number[]>([]);
  /** State variable declarations */
  const [wmsLayers] = useState(() =>
    [
      'currentPositionLayer1',
      'currentPositionLayer2',
      'currentPositionLayer3',
    ].map((item) => auratWMS(item)),
  );
  const [basemaps] = useState([virastokarttaMV, ...wmsLayers]);

  /** Selected hour ranges */
  const hoursFromRanges = {
    '0-4h': [0, 4],
    '4-12h': [4, 12],
    '12-24h': [12, 24],
  };
  const hourRanges = Object.keys(
    hoursFromRanges,
  ) as (keyof typeof hoursFromRanges)[];
  /** Selected ranges of features to be displayed */
  const [selectedHoursRanges, setSelectedHourRanges] = useState<
    (keyof typeof hoursFromRanges)[]
  >(['0-4h']);

  async function getVehicleFeatures() {
    const data = (
      await Promise.all(
        selectedHoursRanges.map(async (range) =>
          DataController.getVehicleLocations(range),
        ),
      )
    ).flat(1);

    return createVehicleFeatures(data, EPSG3067);
  }

  /** Get OpenLayers features for the vehicles from external api source */
  const {
    data: vehicleLocationFeatures,
    refetch,
    isFetching,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['snowFeatures'],
    queryFn: getVehicleFeatures,
  });

  /** Notification snackbar */
  const { notify } = useNotification();
  /** Current map zoom level */
  const [mapZoom, setMapZoom] = useState<number | null>(null);
  /** Current map center coordinates */
  const [mapCenter, setMapCenter] = useState([327000, 6822500]);

  useEffect(() => {
    if (addressCenter.length !== 0) {
      setMapCenter(addressCenter);
    }
  }, [addressCenter]);

  useEffect(() => {
    /** Set interval to update every 15 minutes */
    const interval = setInterval(
      () => {
        refetch();

        /** Refresh WMS layers */
        wmsLayers.map((layer) => layer?.getSource()?.refresh());
      },
      1000 * 60 * 15,
    );

    return () => clearInterval(interval);
  }, []);

  /** When selected hour ranges changes, render corresponding WMS layers and refresh external api data */
  useEffect(() => {
    wmsLayers.forEach((layer) => {
      switch (layer.get('id')) {
        case 'currentPositionLayer1':
          layer.setVisible(selectedHoursRanges.includes(hourRanges[0]));
          break;
        case 'currentPositionLayer2':
          layer.setVisible(selectedHoursRanges.includes(hourRanges[1]));
          break;
        case 'currentPositionLayer3':
          layer.setVisible(selectedHoursRanges.includes(hourRanges[2]));
          break;
        default:
          break;
      }
    });
    refetch();
  }, [selectedHoursRanges]);

  useEffect(() => {
    if (isError)
      notify({
        message: 'Virhe haettaessa ajoneuvojen sijaintitietoja',
        type: 'error',
      });
  }, [isError]);

  /** Date range has changed */
  function changeRange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    setSelectedHourRanges((prev) => {
      if (prev.includes(name as (typeof hourRanges)[number]))
        return prev.filter((range) => range !== event.target.name);
      return prev.concat(name as (typeof hourRanges)[number]);
    });
  }

  function handleSearchResults(position: number[]) {
    setAddressCenter(position);
    /** Shift zoom level just a tiny amount to update React state */
    setMapZoom(13 + Math.random() / 100);
  }

  return (
    <ThemeProvider theme={applicationTheme}>
      <Box sx={{ height: '100vh', width: '100vw' }}>
        {isFetching && (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1200,
            }}
          />
        )}
        <Map
          center={mapCenter}
          features={isLoading ? [] : vehicleLocationFeatures}
          layers={basemaps}
          projection={EPSG3067}
          minZoom={9}
          zoom={mapZoom}
        ></Map>
        <Info />
        <RangeSelector
          onRangeChange={changeRange}
          hourRanges={hourRanges}
          selectedHourRanges={selectedHoursRanges}
        />
        <AddressSearch
          center={[327000, 6822500]}
          handleSetSearchResults={handleSearchResults}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Application;
