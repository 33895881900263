import { ApiVehicleLocation } from '@root/server/types';

const DataController = {
  /** Get vehicle locations between two dates */
  async getVehicleLocations({
    start,
    end,
  }: {
    start: number;
    end: number;
  }): Promise<ApiVehicleLocation[][]> {
    const url = `/api/vehicles/locations?start=${start}&end=${end}`;

    const response = await fetch(url, { method: 'GET' });
    if (!response.ok) throw new Error('Error finding vehicle locations');

    return await response.json();
  },
  async getVehicleStream(fromDate: string, toDate: string) {
    const url = `/api/vehicle-stream`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate }),
    });
    if (!response.ok) throw new Error('Error finding vechile history data');

    const json = await response.json();
    return json;
  },
};
export default DataController;
