import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import { SearchResultFeature, SearchSuggestion } from "@root/server/types";

// StyleKeys = "base" | "popperContainer" ...
// Record<StyleKeys, SystemStyleObject<Theme>>

const styles = {
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  searchBar: {
    position: "absolute",
    top: "20px",
    width: "200px",
  },
  inputRoot: {
    padding: 0,
    backgroundColor: "treBackground",
    opacity: 0.85,
    color: "fontColor",
    borderRadius: "30px",
  },
  input: {
    "& .MuiInputBase-input": { textAlign: "center" },
  },
  suggestionContainer: {
    marginTop: "10px",
    color: "fontColor",
    overflow: "hidden",
    backgroundColor: "treBackground",
    opacity: 0.85,
  },
};

interface Props {
  center: number[];
  handleSetSearchResults: (coordinates: number[], projection: string) => void;
}

export default function AddressSearch(props: Props) {
  const [value, setValue] = useState("");
  const [addressQueryUrl, setAddressQueryUrl] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  // https://geodata.tampere.fi/geoserver/osoitteet/wfs?request=GetFeature&version=1.1.0&typename=osoitteet:MV_KATUOSOITTEET_GSVIEW&maxFeatures=100&outputformat=json&cql_filter=KATUOSOITE%20ILIKE%20%27pellavatehtaankatu%2019%25%27
  useEffect(() => {
    if (value && value.trim().length > 2) {
      //const projectedCenter = toLonLat(props.center, 'EPSG:3067');

      const inputValue = value.trim().toLowerCase();
      /* Geocoding API */
      const apiBaseUrl = "https://geodata.tampere.fi/geoserver/osoitteet/";
      setAddressQueryUrl(
        `${apiBaseUrl}wfs?request=GetFeature&version=1.1.0&typename=osoitteet:MV_KATUOSOITTEET_GSVIEW&maxFeatures=100&outputformat=json&cql_filter=KATUOSOITE%20ILIKE%20%27${inputValue}%25%27&srsName=EPSG:3067 `
      );
    } else {
      setSuggestions([]);
      setAddressQueryUrl(null);
    }
  }, [value]);

  const selectAddress = (suggestion: SearchSuggestion) => {
    props.handleSetSearchResults(suggestion.coordinates, "EPSG:3067");
    setValue("");
    setSuggestions([]);
    setAddressQueryUrl(null);
  };

  useEffect(() => {
    if (addressQueryUrl)
      fetch(addressQueryUrl)
        .then((results) => results.json())
        .then((data) => {
          if (
            data.features &&
            Array.isArray(data.features) &&
            data.features.length > 0
          ) {
            const suggestionsArray = data.features.map(
              (feature: SearchResultFeature) => ({
                label: feature.properties.KATUOSOITE,
                coordinates: feature.geometry.coordinates,
              })
            );
            setSuggestions(suggestionsArray.slice(0, 5));
          } else {
            setSuggestions([]);
          }
        });
  }, [addressQueryUrl]);

  const onInputChange = (
    input: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(input.target.value);
  };

  return (
    <Box sx={styles.searchBarContainer}>
      <Box sx={styles.searchBar}>
        <TextField
          placeholder="Hämeenkatu 18"
          onChange={onInputChange}
          fullWidth
          variant="outlined"
          autoFocus={false}
          value={value}
          InputProps={{
            sx: {
              ...styles.inputRoot,
              ...styles.input,
            },
          }}
        />
        {suggestions.length > 0 && (
          <Paper sx={styles.suggestionContainer}>
            <List component="nav" aria-label="addresses">
              {suggestions.map((suggestion, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => selectAddress(suggestion)}
                >
                  <ListItemText primary={suggestion.label} />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        )}
      </Box>
    </Box>
  );
}
