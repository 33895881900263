import { getWidth, getTopLeft } from 'ol/extent.js';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { Icon, Style } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import { Point } from 'ol/geom';
import { Projection, fromLonLat } from 'ol/proj';

import chevron from '@src/static/chevron';
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import simplify from '@turf/simplify';

function simplifyFeature(feature: Feature<LineString>) {
  const format = new GeoJSON();
  const simplifiedFeature = simplify(format.writeFeatureObject(feature) as any);
  return format.readFeature(simplifiedFeature);
}

const createTileGridTRE = (projection: Projection, tileSize = 256) => {
  const projectionExtent = projection.getExtent();
  const size = getWidth(projectionExtent) / tileSize;
  const resolutions = new Array(16);
  const matrixIds = new Array(16);

  for (let z = 0; z < 16; z++) {
    resolutions[z] = size / Math.pow(2, z);
    matrixIds[z] = `JHS:${z}`;
  }

  const tileGrid = new WMTSTileGrid({
    tileSize: [tileSize, tileSize],
    origin: getTopLeft(projectionExtent),
    resolutions,
    matrixIds,
  });

  return tileGrid;
};

const createArrowFeature = (
  pos: { coordinates: number[]; direction: number },
  projection: Projection,
  chevronColor: string
) => {
  const feat = new Feature({
    geometry: new Point(fromLonLat(pos.coordinates, projection)),
  });

  const scale = 0.55;
  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      height: scale * 48,
      width: 1.5 * scale * 48,
      //scale: 0.75,
      rotation: (Math.PI / 180) * (pos.direction + 90),
      src: `data:image/svg+xml;utf8,${chevron}`,
      color: chevronColor,
    }),
  });

  feat.setStyle(iconStyle);
  return feat;
};

const createLinestringFromPoints = (
  pointsArray: Coordinate[],
  color: string
) => {
  const feature = simplifyFeature(
    new Feature({
      geometry: new LineString(pointsArray),
    })
  );

  const style = new Style({
    stroke: new Stroke({
      color,
      width: 4,
    }),
  });

  feature.setStyle(style);

  return feature;
};

export { createTileGridTRE, createLinestringFromPoints, createArrowFeature };
